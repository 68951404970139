import { useMemo } from "react";
import { applyModelMetadata, applyRestrictivePurposes, prepareTcfString, restoreTcf, getCurrentTcfStringFromCookie } from "../tcf";

/**
 * Merges the localized `tcf` and `tcfMetadata` together so it can be
 * used inside our `BannerContextProps` and returns a valid `GVL` instance.
 */
function useTcf(active, tcf, tcfMeta) {
  return useMemo(function () {
    if (process.env.IS_TCF === "1" && active && Object.values(tcf.vendors).length > 0) {
      var gvl;
      var model;
      var restored = getCurrentTcfStringFromCookie(tcf, tcfMeta); // First, try to resolve from user consent (cookie value)

      if (restored) {
        gvl = restored.gvl;
        model = restored.model;
      } else {
        // No consent given, let's create an empty model
        var newTcf = restoreTcf({
          tcf: tcf,
          tcfMeta: tcfMeta,
          tcfString: ""
        });
        gvl = newTcf.gvl;
        model = newTcf.model;
      }

      applyModelMetadata(model, tcfMeta);
      applyRestrictivePurposes(model, tcf.vendorConfigurations);

      if (!restored) {
        prepareTcfString(model, "initial");
      }

      return {
        gvl: gvl,
        model: model,
        original: tcf,
        metadata: tcfMeta
      };
    } else {
      return undefined;
    }
  }, [active, tcf, tcfMeta]);
}

export { useTcf };