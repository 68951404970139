import { OPT_IN_ALL_EVENT, BANNER_PRE_DECISION_SHOW_EVENT } from "../types";
import { getUserDecision } from ".";
/**
 * Listen to opt-in so we can safely print the UUID of the current user to the
 * `PrintUuidShortcode.php` HTML tag.
 */

function printUuidToShortcode() {
  var elements = Array.prototype.slice.call(document.querySelectorAll(".rcb-consent-print-uuid"));
  document.addEventListener(BANNER_PRE_DECISION_SHOW_EVENT, function () {
    elements.forEach(function (element) {
      return element.innerHTML = element.getAttribute("data-fallback");
    });
  });
  document.addEventListener(OPT_IN_ALL_EVENT, function () {
    var consent = getUserDecision();
    elements.forEach(function (element) {
      return element.innerHTML = consent === false ? element.getAttribute("data-fallback") : consent.uuid;
    });
  });
}

export { printUuidToShortcode };