import { bannerInlineStylesScale } from "..";

function bannerButtonsInlineStyleCommon(_ref, mobile, isHover, framed) {
  var name = _ref.name,
      order = _ref.order,
      fullWidth = _ref.fullWidth,
      marginLeft = _ref.marginLeft,
      marginRight = _ref.marginRight,
      type = _ref.type,
      borderRadius = _ref.borderRadius,
      bg = _ref.bg,
      hoverBg = _ref.hoverBg,
      fontSize = _ref.fontSize,
      textAlign = _ref.textAlign,
      linkTextDecoration = _ref.linkTextDecoration,
      fontColor = _ref.fontColor,
      fontWeight = _ref.fontWeight,
      hoverFontColor = _ref.hoverFontColor,
      borderWidth = _ref.borderWidth,
      borderColor = _ref.borderColor,
      hoverBorderColor = _ref.hoverBorderColor,
      padding = _ref.padding,
      antiAdBlocker = _ref.antiAdBlocker,
      pageRequestUuid4 = _ref.pageRequestUuid4;
  var useBorderWidth = bannerInlineStylesScale(mobile, pageRequestUuid4, +borderWidth);
  var style = {
    textDecoration: type === "link" ? linkTextDecoration : "none",
    borderRadius: +borderRadius,
    cursor: type === "button" ? "pointer" : undefined,
    backgroundColor: type === "button" ? isHover ? hoverBg : bg : undefined,
    fontSize: bannerInlineStylesScale(mobile, pageRequestUuid4, +fontSize),
    fontWeight: fontWeight,
    color: isHover ? hoverFontColor : fontColor,
    transition: "background-color 250ms, color 250ms, border-color 250ms",
    margin: "0 ".concat(marginRight, "px ").concat(bannerInlineStylesScale(mobile, pageRequestUuid4, 10, undefined, true), "px ").concat(marginLeft, "px"),
    border: type === "button" && useBorderWidth > 0 ? "".concat(useBorderWidth, "px solid ").concat(isHover ? hoverBorderColor : borderColor) : undefined,
    padding: bannerInlineStylesScale(mobile, pageRequestUuid4, padding, undefined, type !== "button").map(function (p) {
      return "".concat(p, "px");
    }).join(" "),
    overflow: "hidden",
    outline: framed ? "rgb(255, 94, 94) solid 5px" : undefined,
    flex: fullWidth ? "1 1 100%" : "0 0 calc(50% - ".concat(marginLeft + marginRight, "px)"),
    display: "flex",
    alignItems: "center",
    // text-align is not supported within a `display: flex`
    justifyContent: textAlign === "right" ? "flex-end" : textAlign === "center" ? "center" : "left",
    order: order
  };
  return {
    className: antiAdBlocker === "y" ? undefined : "rcb-btn-".concat(name),
    style: style
  };
}

export { bannerButtonsInlineStyleCommon };