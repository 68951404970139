import { useEffect } from "react";
import { usePlainCss } from ".";
import { useBanner } from "../contexts";
/**
 * Pass an HTML refs and we will provide a root stylesheet with the height dimensions.
 */

function useBannerRenderedHeightsAsCssVars(element, name) {
  var _useBanner = useBanner(),
      pageRequestUuid4 = _useBanner.pageRequestUuid4; // We do directly modify the inline CSS as we do not want to trigger a rerender for the complete banner content


  var cssId = usePlainCss("", "useBannerRenderedHeightsAsCssVars-".concat(name));
  var cssElement = document.getElementById(cssId);
  useEffect(function () {
    var previousHeight = 0;

    var recalculate = function recalculate() {
      var currentHeight = element.getBoundingClientRect().height;

      if (previousHeight !== currentHeight) {
        previousHeight = currentHeight;
        cssElement.innerHTML = "#".concat(pageRequestUuid4, "{--rendered-height-").concat(name, ": ").concat(Math.floor(currentHeight), "px;--rendered-height-raw-").concat(name, ": ").concat(currentHeight, "px}");
      }
    };

    if (!element) {
      return function () {// Silence is golden.
      };
    } // Support animations


    var animateContainer = document.querySelector("#".concat(pageRequestUuid4, " div[class*=\"animate__\"]"));
    animateContainer.addEventListener("animationend", recalculate);

    if (window.ResizeObserver) {
      var ro = new ResizeObserver(recalculate);
      ro.observe(element);
      return function () {
        ro.disconnect();
        animateContainer.removeEventListener("animationend", recalculate);
      };
    } else {
      var interval = setInterval(recalculate, 150);
      return function () {
        clearInterval(interval);
        animateContainer.removeEventListener("animationend", recalculate);
      };
    }
  }, [element, name]);
}

export { useBannerRenderedHeightsAsCssVars };