import { bannerInlineStylesScale } from "..";

function bannerGroupsInlineStyleDescription(_ref) {
  var fontWeight = _ref.design.fontWeight,
      _ref$group = _ref.group,
      descriptionFontColor = _ref$group.descriptionFontColor,
      descriptionFontSize = _ref$group.descriptionFontSize,
      antiAdBlocker = _ref.customCss.antiAdBlocker,
      mobile = _ref.mobile,
      pageRequestUuid4 = _ref.pageRequestUuid4;
  return {
    className: antiAdBlocker === "y" ? undefined : "rcb-group-description",
    style: {
      color: descriptionFontColor,
      fontSize: bannerInlineStylesScale(mobile, pageRequestUuid4, +descriptionFontSize),
      fontWeight: fontWeight,
      marginTop: 5
    }
  };
}

export { bannerGroupsInlineStyleDescription };