import { bannerInlineStylesScale } from "..";

function bannerFooterInlineStyle(_ref) {
  var _ref$layout = _ref.layout,
      type = _ref$layout.type,
      bannerMaxWidth = _ref$layout.bannerMaxWidth,
      individualLayout = _ref.individualLayout,
      antiAdBlocker = _ref.customCss.antiAdBlocker,
      mobile = _ref.mobile,
      pageRequestUuid4 = _ref.pageRequestUuid4,
      individualPrivacyOpen = _ref.individualPrivacyOpen;
  var style = {
    transition: "width 500ms, max-width 500ms",
    maxWidth: bannerInlineStylesScale(mobile, pageRequestUuid4, type, "banner") === "banner" ? +(individualPrivacyOpen && !individualLayout.inheritBannerMaxWidth ? individualLayout.bannerMaxWidth : bannerMaxWidth) : undefined,
    margin: "auto",
    lineHeight: 1.8
  };
  return {
    className: antiAdBlocker === "y" ? undefined : "rcb-footer",
    style: style
  };
}

export { bannerFooterInlineStyle };