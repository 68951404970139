import { bannerInlineStylesScale } from "..";

function bannerGroupsInlineStyleTitle(_ref) {
  var _ref$group = _ref.group,
      headlineFontSize = _ref$group.headlineFontSize,
      headlineFontColor = _ref$group.headlineFontColor,
      headlineFontWeight = _ref$group.headlineFontWeight,
      antiAdBlocker = _ref.customCss.antiAdBlocker,
      mobile = _ref.mobile,
      pageRequestUuid4 = _ref.pageRequestUuid4;
  return {
    className: antiAdBlocker === "y" ? undefined : "rcb-group",
    style: {
      color: headlineFontColor,
      fontSize: bannerInlineStylesScale(mobile, pageRequestUuid4, headlineFontSize),
      fontWeight: headlineFontWeight,
      textAlign: "left"
    }
  };
}

export { bannerGroupsInlineStyleTitle };