import { bannerInlineStylesScale } from "..";

function bannerGroupsInlineStyleCookieProperty(_ref) {
  var _ref$group = _ref.group,
      groupBorderWidth = _ref$group.groupBorderWidth,
      groupBorderColor = _ref$group.groupBorderColor,
      antiAdBlocker = _ref.customCss.antiAdBlocker,
      mobile = _ref.mobile,
      pageRequestUuid4 = _ref.pageRequestUuid4;
  return {
    className: antiAdBlocker === "y" ? undefined : "rcb-cookie-prop",
    style: {
      borderLeft: bannerInlineStylesScale(mobile, pageRequestUuid4, groupBorderWidth) > 0 ? "1px solid ".concat(groupBorderColor) : undefined,
      paddingLeft: 15
    }
  };
}

export { bannerGroupsInlineStyleCookieProperty };