import { usePageIdToPermalinkMapLink } from ".";

function useBannerLegalLinks(_ref) {
  var privacyPolicy = _ref.privacyPolicy,
      privacyPolicyExternalUrl = _ref.privacyPolicyExternalUrl,
      privacyPolicyIsExternalUrl = _ref.privacyPolicyIsExternalUrl,
      privacyPolicyLabel = _ref.privacyPolicyLabel,
      imprint = _ref.imprint,
      imprintExternalUrl = _ref.imprintExternalUrl,
      imprintIsExternalUrl = _ref.imprintIsExternalUrl,
      imprintLabel = _ref.imprintLabel;
  var linkPrivacyPolicy = usePageIdToPermalinkMapLink(privacyPolicy);
  var linkImprint = usePageIdToPermalinkMapLink(imprint);

  if (privacyPolicyIsExternalUrl) {
    linkPrivacyPolicy = privacyPolicyExternalUrl;
  }

  if (imprintIsExternalUrl) {
    linkImprint = imprintExternalUrl;
  }

  return {
    linkPrivacyPolicy: privacyPolicyLabel ? {
      url: linkPrivacyPolicy,
      label: privacyPolicyLabel
    } : false,
    linkImprint: imprintLabel ? {
      url: linkImprint,
      label: imprintLabel
    } : false
  };
}

export { useBannerLegalLinks };