import { bannerInlineStylesScale } from "..";

function bannerContentInlineStyle(_ref) {
  var _document$getElementB;

  var type = _ref.layout.type,
      antiAdBlocker = _ref.customCss.antiAdBlocker,
      showCloseIcon = _ref.decision.showCloseIcon,
      activeAction = _ref.activeAction,
      individualPrivacyOpen = _ref.individualPrivacyOpen,
      mobile = _ref.mobile,
      pageRequestUuid4 = _ref.pageRequestUuid4;
  var innerHeight = ((_document$getElementB = document.getElementById(pageRequestUuid4)) === null || _document$getElementB === void 0 ? void 0 : _document$getElementB.clientHeight) || window.innerHeight;
  var mobileMaxHeight = +mobile.maxHeight;
  var useMobileMaxHeight = mobileMaxHeight > innerHeight ? innerHeight : mobileMaxHeight;
  var closeIconDistance = showCloseIcon || activeAction ? 51 : 0;
  return {
    className: antiAdBlocker === "y" ? undefined : "rcb-content",
    style: {
      position: "relative",
      maxHeight: bannerInlineStylesScale(mobile, pageRequestUuid4, innerHeight - (type === "banner" ? 0 : 20) - closeIconDistance, individualPrivacyOpen ? innerHeight - closeIconDistance : useMobileMaxHeight - closeIconDistance),
      overflow: "auto"
    }
  };
}

export { bannerContentInlineStyle };