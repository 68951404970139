import { Fragment } from "react";
import { bannerGroupsInlineStyle, BannerGroup, bannerInlineStylesScale } from ".";
import { useBanner } from "../../contexts";

var BannerGroups = function BannerGroups() {
  var banner = useBanner();
  var groups = banner.groups,
      groupSpacing = banner.group.groupSpacing,
      mobile = banner.mobile,
      pageRequestUuid4 = banner.pageRequestUuid4;
  var useGroups = groups.filter(function (_ref) {
    var items = _ref.items;
    return items.length;
  });
  return h("div", bannerGroupsInlineStyle(banner), useGroups.map(function (group, index) {
    return h(Fragment, {
      key: group.id
    }, h(BannerGroup, {
      key: group.id,
      group: group
    }), index !== useGroups.length - 1 && h("div", {
      style: {
        height: bannerInlineStylesScale(mobile, pageRequestUuid4, +groupSpacing)
      }
    }));
  }));
};

export { BannerGroups };