import { useBannerButtons } from "../../hooks";
import { useBanner } from "../../contexts";
import { bannerInlineStylesScale, BANNER_SCALING_WHEN_WINDOW_WIDTH_LOWER } from "./inlineStyles";
import { getOtherOptionsFromWindow } from "../../utils";
import { CloseIcon } from "..";

var BannerCloseIcon = function BannerCloseIcon() {
  var _useBanner = useBanner(),
      _useBanner$headerDesi = _useBanner.headerDesign,
      fontSize = _useBanner$headerDesi.fontSize,
      fontColor = _useBanner$headerDesi.fontColor,
      acceptEssentials = _useBanner.texts.acceptEssentials,
      mobile = _useBanner.mobile,
      activeAction = _useBanner.activeAction,
      pageRequestUuid4 = _useBanner.pageRequestUuid4,
      _useBanner$buttonClic = _useBanner.buttonClicked,
      buttonClicked = _useBanner$buttonClic === void 0 ? "" : _useBanner$buttonClic;

  var _useBannerButtons = useBannerButtons(),
      buttonClickedCloseIcon = _useBannerButtons.buttonClickedCloseIcon,
      closeIcon = _useBannerButtons.closeIcon;

  var _getOtherOptionsFromW = getOtherOptionsFromWindow(),
      _getOtherOptionsFromW2 = _getOtherOptionsFromW.bannerI18n,
      close = _getOtherOptionsFromW2.close,
      closeWithoutSaving = _getOtherOptionsFromW2.closeWithoutSaving;

  var container = document.getElementById(pageRequestUuid4);
  return h(CloseIcon, {
    width: bannerInlineStylesScale(mobile, pageRequestUuid4, fontSize),
    color: fontColor,
    tooltipText: activeAction ? activeAction === "change" ? closeWithoutSaving : close : acceptEssentials,
    tooltipAlways: (container === null || container === void 0 ? void 0 : container.clientWidth) < BANNER_SCALING_WHEN_WINDOW_WIDTH_LOWER,
    framed: buttonClicked === buttonClickedCloseIcon,
    renderInContainer: container,
    onClick: closeIcon
  });
};

export { BannerCloseIcon };