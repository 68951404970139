import _createForOfIteratorHelper from "@babel/runtime/helpers/createForOfIteratorHelper";
import { createRegxpPatternFromWildcardedName } from "../../utils";
import Cookie from "js-cookie";
import { applyDynamicsToHtml } from "@devowl-wp/headless-content-unblocker";
/**
 * Delete cookies on client side (currently only `localStorage` and `sessionStorage`).
 */

function deleteCookiesLocally(technicalDefinitions, dynamics) {
  var _iterator = _createForOfIteratorHelper(technicalDefinitions),
      _step;

  try {
    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      var _step$value = _step.value,
          type = _step$value.type,
          name = _step$value.name;
      // Create valid Regxp pattern
      var pattern = new RegExp(createRegxpPatternFromWildcardedName(applyDynamicsToHtml(name, dynamics)), "g");

      switch (type) {
        case "http":
          {
            for (var _i = 0, _Object$keys = Object.keys(Cookie.get()); _i < _Object$keys.length; _i++) {
              var key = _Object$keys[_i];

              if (pattern.test(key)) {
                Cookie.remove(key);
              }
            }

            break;
          }

        case "local":
        case "session":
          {
            try {
              var useStorage = type === "local" ? window.localStorage : window.sessionStorage;

              if (useStorage) {
                for (var _i2 = 0, _Object$keys2 = Object.keys(useStorage); _i2 < _Object$keys2.length; _i2++) {
                  var itemName = _Object$keys2[_i2];

                  if (pattern.test(itemName)) {
                    try {
                      // Fallback to `null` because `removeItem` can have issues on some browsers
                      useStorage.setItem(itemName, null);
                    } catch (e) {// Silence is golden. A `QuotaExceededError` can be thrown but we ignore it, because we remove the item
                    } // Try as long (but max 100 times) until deleted


                    var tries = 0;

                    while (useStorage.getItem(itemName) && tries < 100) {
                      tries++;
                      useStorage.removeItem(itemName);
                    }
                  }
                }
              }
            } catch (e) {
              // Browser does not support localStorage, let's do nothing...
              continue;
            }

            break;
          }

        default:
          break;
      }
    }
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }
}

export { deleteCookiesLocally };