import { Fragment } from "react";
import { useBanner } from "../../contexts";
import { BannerButton, BannerDevHint } from ".";
import { getOtherOptionsFromWindow } from "../../utils";
import { useBannerButtons } from "../../hooks";

var BannerButtons = function BannerButtons() {
  var banner = useBanner();
  var activeAction = banner.activeAction,
      decision = banner.decision,
      _banner$texts = banner.texts,
      acceptAll = _banner$texts.acceptAll,
      acceptEssentials = _banner$texts.acceptEssentials,
      acceptIndividual = _banner$texts.acceptIndividual,
      saveButton = banner.saveButton,
      individualTexts = banner.individualTexts,
      individualPrivacyOpen = banner.individualPrivacyOpen,
      didGroupFirstChange = banner.didGroupFirstChange,
      _banner$buttonClicked = banner.buttonClicked,
      buttonClicked = _banner$buttonClicked === void 0 ? "" : _banner$buttonClicked;

  var _getOtherOptionsFromW = getOtherOptionsFromWindow(),
      isPro = _getOtherOptionsFromW.isPro;

  var _useBannerButtons = useBannerButtons(),
      buttonClickedAll = _useBannerButtons.buttonClickedAll,
      buttonClickedEssentials = _useBannerButtons.buttonClickedEssentials,
      buttonClickedCustom = _useBannerButtons.buttonClickedCustom,
      handleAll = _useBannerButtons.acceptAll,
      handleEssentials = _useBannerButtons.acceptEssentials,
      handleIndividual = _useBannerButtons.acceptIndividual,
      openIndividualPrivacy = _useBannerButtons.openIndividualPrivacy; // We expect a reload of the page for the "change" action (keeps also dialog open)


  var busyOnClick = activeAction === "change";
  return h(Fragment, null, !individualPrivacyOpen && isPro && decision.groupsFirstView && decision.saveButton === "afterChangeAll" && didGroupFirstChange ? h(BannerButton, {
    onClick: handleIndividual,
    type: decision.acceptAll,
    inlineStyle: "acceptAll",
    framed: buttonClicked === buttonClickedCustom,
    busyOnClick: busyOnClick
  }, individualTexts.save) : h(BannerButton, {
    onClick: handleAll,
    type: decision.acceptAll,
    inlineStyle: "acceptAll",
    framed: buttonClicked === buttonClickedAll,
    busyOnClick: busyOnClick
  }, acceptAll), activeAction !== "change" && h(BannerButton, {
    onClick: handleEssentials,
    type: decision.acceptEssentials,
    inlineStyle: "acceptEssentials",
    framed: buttonClicked === buttonClickedEssentials,
    busyOnClick: busyOnClick
  }, acceptEssentials), (individualPrivacyOpen || isPro && decision.groupsFirstView && (decision.saveButton === "always" || decision.saveButton === "afterChange" && didGroupFirstChange)) && h(BannerButton, {
    onClick: handleIndividual,
    type: saveButton.type,
    inlineStyle: "save",
    framed: buttonClicked === buttonClickedCustom,
    busyOnClick: busyOnClick
  }, individualTexts.save), !individualPrivacyOpen && h(BannerButton, {
    type: decision.acceptIndividual,
    onClick: openIndividualPrivacy,
    inlineStyle: "acceptIndividual",
    framed: buttonClicked.startsWith("ind_"),
    busyOnClick: busyOnClick
  }, acceptIndividual), h(BannerDevHint, null));
};

export { BannerButtons };