import { bannerInlineStylesScale } from "..";
/**
 * Use `visible` together with `useLayoutEffect` to avoid flickering.
 * This is needed because the SVG icons of ant are a bit flickering.
 */

function bannerGroupsInlineStyleCheckbox(_ref, visible, checked, isDisabled, fontSize) {
  var borderRadius = _ref.layout.borderRadius,
      _ref$group = _ref.group,
      headlineFontSize = _ref$group.headlineFontSize,
      checkboxBg = _ref$group.checkboxBg,
      checkboxBorderWidth = _ref$group.checkboxBorderWidth,
      checkboxBorderColor = _ref$group.checkboxBorderColor,
      checkboxActiveBg = _ref$group.checkboxActiveBg,
      checkboxActiveBorderColor = _ref$group.checkboxActiveBorderColor,
      checkboxActiveColor = _ref$group.checkboxActiveColor,
      antiAdBlocker = _ref.customCss.antiAdBlocker,
      mobile = _ref.mobile,
      pageRequestUuid4 = _ref.pageRequestUuid4;
  var useFontSize = bannerInlineStylesScale(mobile, pageRequestUuid4, fontSize || headlineFontSize);
  var useCheckboxBorderWidth = bannerInlineStylesScale(mobile, pageRequestUuid4, +checkboxBorderWidth);
  var height = +useFontSize + useCheckboxBorderWidth * 2 + 6;
  return {
    className: antiAdBlocker === "y" ? undefined : "rcb-checkbox",
    style: {
      cursor: isDisabled ? "not-allowed" : "pointer",
      opacity: isDisabled ? 0.5 : undefined,
      color: checked ? checkboxActiveColor : checkboxBg,
      display: visible ? "inline-block" : "none",
      background: checked ? checkboxActiveBg : checkboxBg,
      border: "".concat(useCheckboxBorderWidth, "px solid ").concat(checked ? checkboxActiveBorderColor : checkboxBorderColor),
      padding: 3,
      height: height,
      width: height,
      marginRight: 10,
      borderRadius: +borderRadius,
      verticalAlign: "middle",
      lineHeight: 0,
      boxSizing: "border-box"
    }
  };
}

export { bannerGroupsInlineStyleCheckbox };