import _objectSpread from "@babel/runtime/helpers/objectSpread2";
import _createForOfIteratorHelper from "@babel/runtime/helpers/createForOfIteratorHelper";
import { useCallback } from "react";
import { useBanner } from "../contexts";
/**
 * Provide callbacks for all the available button types in our cookie banner:
 *
 * - Accept all
 * - Continue without consent (accept essentials)
 * - Save custom choices (no changes to the current selected groups and services)
 * - Change individual privacy preferences
 */

function useBannerButtons() {
  var _useBanner = useBanner(),
      individualPrivacyOpen = _useBanner.individualPrivacyOpen,
      onSave = _useBanner.onSave,
      updateGroupChecked = _useBanner.updateGroupChecked,
      updateCookieChecked = _useBanner.updateCookieChecked,
      groups = _useBanner.groups,
      essentialGroup = _useBanner.essentialGroup,
      updateIndividualPrivacyOpen = _useBanner.updateIndividualPrivacyOpen,
      activeAction = _useBanner.activeAction,
      onClose = _useBanner.onClose;

  var buttonClickedAll = individualPrivacyOpen ? "ind_all" : "main_all";
  var buttonClickedEssentials = individualPrivacyOpen ? "ind_essential" : "main_essential";
  var buttonClickedCloseIcon = individualPrivacyOpen ? "ind_close_icon" : "main_close_icon";
  var buttonClickedCustom = individualPrivacyOpen ? "ind_custom" : "main_custom";
  var result = {
    buttonClickedAll: buttonClickedAll,
    buttonClickedEssentials: buttonClickedEssentials,
    buttonClickedCloseIcon: buttonClickedCloseIcon,
    buttonClickedCustom: buttonClickedCustom,
    acceptAll: useCallback(function () {
      groups.forEach(function (g) {
        return updateGroupChecked(g.id, true);
      });
      onSave(false, buttonClickedAll);
    }, [buttonClickedAll]),

    /**
     * This includes the complete "Essential" group + legal basis = legitimate interests.
     */
    acceptEssentials: useCallback(function () {
      var isCloseIcon = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      groups.forEach(function (_ref) {
        var slug = _ref.slug,
            id = _ref.id,
            items = _ref.items;

        if (slug === essentialGroup) {
          updateGroupChecked(id, true);
        } else {
          var _iterator = _createForOfIteratorHelper(items),
              _step;

          try {
            for (_iterator.s(); !(_step = _iterator.n()).done;) {
              var _step$value = _step.value,
                  legalBasis = _step$value.legalBasis,
                  cookieId = _step$value.id;
              updateCookieChecked(id, cookieId, legalBasis === "legitimate-interest");
            }
          } catch (err) {
            _iterator.e(err);
          } finally {
            _iterator.f();
          }
        }
      });
      onSave(false, isCloseIcon ? buttonClickedCloseIcon : buttonClickedEssentials);
    }, [buttonClickedEssentials]),
    acceptIndividual: useCallback(function () {
      return onSave(false, buttonClickedCustom);
    }, [buttonClickedCustom]),
    openIndividualPrivacy: useCallback(function () {
      updateIndividualPrivacyOpen(true);
    }, [updateIndividualPrivacyOpen])
  };
  return _objectSpread(_objectSpread({}, result), {}, {
    closeIcon: useCallback(function () {
      if (!activeAction) {
        // If we do not have yet a consent and it shows the banner for the first time,
        // the close icon is equivalent to "Continue without consent"
        result.acceptEssentials(true);
      } else {
        onClose();
      }
    }, [activeAction, onClose, result.acceptEssentials])
  });
}

export { useBannerButtons };