import { bannerInlineStylesScale } from "..";

function bannerInlineStyleBodyDottedGroupItem(_ref) {
  var fontSize = _ref.design.fontSize,
      _ref$bodyDesign = _ref.bodyDesign,
      dottedGroupsInheritFontSize = _ref$bodyDesign.dottedGroupsInheritFontSize,
      dottedGroupsFontSize = _ref$bodyDesign.dottedGroupsFontSize,
      mobile = _ref.mobile,
      pageRequestUuid4 = _ref.pageRequestUuid4;
  return {
    className: undefined,
    style: {
      paddingRight: 10,
      fontSize: bannerInlineStylesScale(mobile, pageRequestUuid4, dottedGroupsInheritFontSize ? +fontSize : +dottedGroupsFontSize),
      whiteSpace: "nowrap",
      display: "inline-block"
    }
  };
}

export { bannerInlineStyleBodyDottedGroupItem };