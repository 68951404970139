import _extends from "@babel/runtime/helpers/extends";
import { useMemo, Fragment } from "react";
import { useBanner } from "../../contexts";
import { bannerInlineStylesLeftSide, bannerInlineStylesRightSide, bannerInlineStyleBody, bannerInlineStyleBodyContainer, bannerInlineStyleBodyDescription, bannerInlineStyleBodyTeaching, bannerInlineStyleBodyTeachingsSeparator, BannerGroups, BannerTcfGroups, BannerDottedGroups, BannerButtons, BannerHistorySelect, BannerTcfStacks, bannerInlineStylesScale, BANNER_SCALING_WHEN_WINDOW_WIDTH_LOWER } from ".";
import { usePlainCss } from "../../hooks";
import { expandGroupsServiceVariable } from "../../others";

var BannerBody = function BannerBody(_ref) {
  var _document$getElementB;

  var leftSideContainerRef = _ref.leftSideContainerRef,
      rightSideContainerRef = _ref.rightSideContainerRef;
  var banner = useBanner();
  var groups = banner.groups,
      type = banner.layout.type,
      mobile = banner.mobile,
      isEPrivacyUSA = banner.ePrivacyUSA,
      isAgeNotice = banner.ageNotice,
      teachingsSeparatorActive = banner.bodyDesign.teachingsSeparatorActive,
      _banner$texts = banner.texts,
      description = _banner$texts.description,
      ePrivacyUSA = _banner$texts.ePrivacyUSA,
      ageNoticeBanner = _banner$texts.ageNoticeBanner,
      consentForwardingExternalHostsText = _banner$texts.consentForwardingExternalHosts,
      tcfGlobalScope = _banner$texts.tcfGlobalScope,
      tcf = banner.tcf,
      consentForwardingExternalHosts = banner.consentForwardingExternalHosts,
      individualPrivacyOpen = banner.individualPrivacyOpen,
      individualTexts = banner.individualTexts,
      activeAction = banner.activeAction,
      pageRequestUuid4 = banner.pageRequestUuid4; // Make the content responsive

  var leftSideId = "bnnr-body-leftSide-".concat(pageRequestUuid4);
  var rightSideId = "bnnr-body-rightSide-".concat(pageRequestUuid4);
  var contentId = "cntnt-".concat(pageRequestUuid4);
  usePlainCss(" #".concat(leftSideId, " img {\n  max-width: 100%;\n  height: auto;\n}\n\n").concat(((_document$getElementB = document.getElementById(pageRequestUuid4)) === null || _document$getElementB === void 0 ? void 0 : _document$getElementB.clientWidth) > BANNER_SCALING_WHEN_WINDOW_WIDTH_LOWER ? "" : "#".concat(leftSideId, " {\n    float: initial !important;\n    width: auto !important;\n    padding-right: initial !important;\n    ").concat(bannerInlineStylesScale(mobile, pageRequestUuid4, type, "banner") === "banner" && !individualPrivacyOpen ? "padding-bottom: 10px !important;" : individualPrivacyOpen ? "padding-top: 10px !important;" : "", "\n    }\n\n    #").concat(contentId, " {\n        min-height: calc(var(--rendered-height-header) + var(--rendered-height-footer) + var(--rendered-height-rightSideContainer) + 60px);\n    }\n    \n    #").concat(rightSideId, " {\n    float: initial !important;\n    width: auto !important;\n    max-width: initial !important;\n    margin: initial !important;\n    ").concat(individualPrivacyOpen ? "padding-bottom: 5px !important;" : "padding-top: 10px !important;", "\n    position: sticky;\n    }"))); // Prepare description text

  var consentForwardingExternalHostsFinalText = consentForwardingExternalHosts ? consentForwardingExternalHostsText.replace(/{{websites}}/g, consentForwardingExternalHosts.join(", ")) : "";
  var tcfGlobalScopeFinalText = tcf ? tcf.model.isServiceSpecific ? "" : tcfGlobalScope : "";
  var useDescription = [individualPrivacyOpen ? individualTexts.description : description, consentForwardingExternalHostsFinalText, tcfGlobalScopeFinalText].filter(Boolean).join(" ");
  var useEPrivacyUSA = expandGroupsServiceVariable(groups, tcf ? {
    gvl: tcf.gvl,
    vendorConfigurations: tcf.original.vendorConfigurations
  } : undefined, isEPrivacyUSA ? ePrivacyUSA : "", function (cookie, tcf) {
    return (cookie === null || cookie === void 0 ? void 0 : cookie.ePrivacyUSA) || (tcf === null || tcf === void 0 ? void 0 : tcf.vendorConfiguration.ePrivacyUSA) === 1;
  }, 3, true); // Calculate the body content depending on TCF availability and if second view got opened

  var stackOrGroups = useMemo(function () {
    if (process.env.IS_TCF === "1" && tcf) {
      return individualPrivacyOpen ? h(BannerTcfGroups, null) : h(BannerTcfStacks, null);
    } else {
      return individualPrivacyOpen ? h(BannerGroups, null) : h(BannerDottedGroups, null);
    }
  }, [individualPrivacyOpen, tcf]);
  var leftSide = h("div", _extends({
    ref: leftSideContainerRef,
    key: "leftSide",
    id: leftSideId
  }, bannerInlineStylesLeftSide(banner)), !!useDescription && h(Fragment, null, h("div", bannerInlineStyleBodyDescription(banner), activeAction === "history" ? h(BannerHistorySelect, null) : h(Fragment, null, h("span", {
    dangerouslySetInnerHTML: {
      __html: useDescription
    }
  }), (isEPrivacyUSA || isAgeNotice) && h(Fragment, null, teachingsSeparatorActive && h("div", null, h("span", bannerInlineStyleBodyTeachingsSeparator(banner))), !!useEPrivacyUSA && h("span", _extends({}, bannerInlineStyleBodyTeaching(banner), {
    dangerouslySetInnerHTML: {
      __html: useEPrivacyUSA
    }
  })), isAgeNotice && !!ageNoticeBanner && h("span", _extends({}, bannerInlineStyleBodyTeaching(banner), {
    dangerouslySetInnerHTML: {
      __html: ageNoticeBanner
    }
  })))))), stackOrGroups);
  var rightSide = activeAction === "history" ? h("div", {
    ref: rightSideContainerRef
  }) : h("div", _extends({
    ref: rightSideContainerRef,
    key: "rightSide",
    id: rightSideId
  }, bannerInlineStylesRightSide(banner)), h(BannerButtons, null));
  return h("div", bannerInlineStyleBodyContainer(banner), h("div", bannerInlineStyleBody(banner), individualPrivacyOpen ? [rightSide, leftSide] : [leftSide, rightSide], h("div", {
    style: {
      clear: "both"
    }
  })));
};

export { BannerBody };