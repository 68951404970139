import { bannerButtonsInlineStyleCommon } from "./";

function bannerButtonsInlineStyleAcceptEssentials(_ref, isHover, framed, isTwoButtonRowLayout) {
  var isTcf = _ref.isTcf,
      acceptEssentials = _ref.decision.acceptEssentials,
      borderRadius = _ref.layout.borderRadius,
      linkTextDecoration = _ref.design.linkTextDecoration,
      _ref$bodyDesign = _ref.bodyDesign,
      acceptEssentialsFontSize = _ref$bodyDesign.acceptEssentialsFontSize,
      acceptEssentialsBg = _ref$bodyDesign.acceptEssentialsBg,
      acceptEssentialsTextAlign = _ref$bodyDesign.acceptEssentialsTextAlign,
      acceptEssentialsBorderColor = _ref$bodyDesign.acceptEssentialsBorderColor,
      acceptEssentialsPadding = _ref$bodyDesign.acceptEssentialsPadding,
      acceptEssentialsBorderWidth = _ref$bodyDesign.acceptEssentialsBorderWidth,
      acceptEssentialsFontColor = _ref$bodyDesign.acceptEssentialsFontColor,
      acceptEssentialsFontWeight = _ref$bodyDesign.acceptEssentialsFontWeight,
      acceptEssentialsHoverBg = _ref$bodyDesign.acceptEssentialsHoverBg,
      acceptEssentialsHoverFontColor = _ref$bodyDesign.acceptEssentialsHoverFontColor,
      acceptEssentialsHoverBorderColor = _ref$bodyDesign.acceptEssentialsHoverBorderColor,
      acceptAllFontWeight = _ref$bodyDesign.acceptAllFontWeight,
      acceptAllFontSize = _ref$bodyDesign.acceptAllFontSize,
      acceptAllPadding = _ref$bodyDesign.acceptAllPadding,
      antiAdBlocker = _ref.customCss.antiAdBlocker,
      mobile = _ref.mobile,
      pageRequestUuid4 = _ref.pageRequestUuid4;
  return bannerButtonsInlineStyleCommon({
    name: "accept-essentials",
    order: isTwoButtonRowLayout ? 0 : 1,
    fullWidth: !isTwoButtonRowLayout,
    marginLeft: 0,
    marginRight: isTwoButtonRowLayout ? 5 : 0,
    type: acceptEssentials,
    borderRadius: borderRadius,
    bg: acceptEssentialsBg,
    hoverBg: acceptEssentialsHoverBg,
    fontSize: isTwoButtonRowLayout || isTcf ? acceptAllFontSize : acceptEssentialsFontSize,
    textAlign: acceptEssentialsTextAlign,
    linkTextDecoration: linkTextDecoration,
    fontColor: acceptEssentialsFontColor,
    fontWeight: isTcf ? acceptAllFontWeight : acceptEssentialsFontWeight,
    hoverFontColor: acceptEssentialsHoverFontColor,
    borderWidth: acceptEssentialsBorderWidth,
    borderColor: acceptEssentialsBorderColor,
    hoverBorderColor: acceptEssentialsHoverBorderColor,
    padding: isTwoButtonRowLayout ? acceptAllPadding : acceptEssentialsPadding,
    antiAdBlocker: antiAdBlocker,
    pageRequestUuid4: pageRequestUuid4
  }, mobile, isHover, framed);
}

export { bannerButtonsInlineStyleAcceptEssentials };