import _objectSpread from "@babel/runtime/helpers/objectSpread2";
import { bannerInlineStylesScale } from "..";

function bannerHeaderInlineStyleLogo(_ref, isRetina) {
  var _ref$headerDesign = _ref.headerDesign,
      logoMaxHeight = _ref$headerDesign.logoMaxHeight,
      logoMargin = _ref$headerDesign.logoMargin,
      logoFitDim = _ref$headerDesign.logoFitDim,
      logoRetinaFitDim = _ref$headerDesign.logoRetinaFitDim,
      antiAdBlocker = _ref.customCss.antiAdBlocker,
      mobile = _ref.mobile,
      pageRequestUuid4 = _ref.pageRequestUuid4;
  var useFitDim = isRetina ? logoRetinaFitDim : logoFitDim;
  var dimStyle = useFitDim ? {
    width: useFitDim[0],
    height: useFitDim[1]
  } : {
    width: "auto",
    height: bannerInlineStylesScale(mobile, pageRequestUuid4, +logoMaxHeight)
  };
  return {
    className: antiAdBlocker === "y" ? undefined : "rcb-logo",
    style: _objectSpread(_objectSpread({}, dimStyle), {}, {
      margin: bannerInlineStylesScale(mobile, pageRequestUuid4, logoMargin).map(function (m) {
        return "".concat(m, "px");
      }).join(" ") //[logoPosition === "left" ? "marginRight" : "marginLeft"]: logoPosition === "above" ? undefined : 15,
      //marginBottom: logoPosition === "above" ? 5 : undefined

    })
  };
}

export { bannerHeaderInlineStyleLogo };