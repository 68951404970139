import _extends from "@babel/runtime/helpers/extends";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useLayoutEffect, useState, useCallback } from "react";
import { bannerGroupsInlineStyleCookie, bannerGroupsInlineStyleCheckbox, BannerCookieProperty } from ".";
import { useBanner } from "../../contexts";
import { getOtherOptionsFromWindow } from "../../utils";
import { getCookieTypeLocalized } from "../../others"; // eslint-disable-next-line import/no-extraneous-dependencies

import { CheckOutlined } from "@ant-design/icons-svg";
import { PlainAntdIconAsSvg } from "..";
import { applyDynamicsToHtml } from "@devowl-wp/headless-content-unblocker";

var BannerCookie = function BannerCookie(_ref) {
  var _consent$groups;

  var group = _ref.group,
      _ref$cookie = _ref.cookie,
      id = _ref$cookie.id,
      name = _ref$cookie.name,
      purpose = _ref$cookie.purpose,
      provider = _ref$cookie.provider,
      providerPrivacyPolicy = _ref$cookie.providerPrivacyPolicy,
      legalBasis = _ref$cookie.legalBasis,
      ePrivacyUSA = _ref$cookie.ePrivacyUSA,
      noTechnicalDefinitions = _ref$cookie.noTechnicalDefinitions,
      technicalDefinitions = _ref$cookie.technicalDefinitions,
      codeDynamics = _ref$cookie.codeDynamics;

  var _useState = useState(false),
      _useState2 = _slicedToArray(_useState, 2),
      isRendered = _useState2[0],
      setIsRendered = _useState2[1];

  var banner = useBanner();
  var isEPrivacyUSA = banner.ePrivacyUSA,
      descriptionFontSize = banner.group.descriptionFontSize,
      consent = banner.consent,
      activeAction = banner.activeAction,
      essentialGroup = banner.essentialGroup;

  var _getOtherOptionsFromW = getOtherOptionsFromWindow(),
      bannerI18n = _getOtherOptionsFromW.bannerI18n;

  var types = getCookieTypeLocalized();
  useLayoutEffect(function () {
    setIsRendered(true);
  }, []);
  var isEssentialGroup = essentialGroup === group.slug;
  var isDisabled = isEssentialGroup || activeAction === "history";
  var isChecked = isEssentialGroup || (((_consent$groups = consent.groups) === null || _consent$groups === void 0 ? void 0 : _consent$groups[group.id]) || []).some(function (c) {
    return c === id;
  });
  var handleCheckbox = useCallback(function () {
    return !isDisabled && banner.updateCookieChecked(group.id, id, !isChecked);
  }, [isDisabled, banner, group, id, isChecked]);
  return h("div", bannerGroupsInlineStyleCookie(banner), h("div", {
    style: {
      marginBottom: 10
    }
  }, h(PlainAntdIconAsSvg, _extends({
    icon: CheckOutlined,
    onClick: handleCheckbox
  }, bannerGroupsInlineStyleCheckbox(banner, isRendered, isChecked, isDisabled, descriptionFontSize))), h("strong", {
    style: {
      verticalAlign: "middle",
      cursor: "pointer"
    },
    onClick: handleCheckbox
  }, name)), !!purpose && h(BannerCookieProperty, {
    label: bannerI18n.purpose,
    value: purpose
  }), h(BannerCookieProperty, {
    label: bannerI18n.legalBasis,
    value: legalBasis === "legal-requirement" ? bannerI18n.legalRequirement : legalBasis === "legitimate-interest" || isEssentialGroup ? bannerI18n.legitimateInterest : bannerI18n.consent
  }), h(BannerCookieProperty, {
    label: bannerI18n.provider,
    value: provider
  }), !!providerPrivacyPolicy && h(BannerCookieProperty, {
    label: bannerI18n.providerPrivacyPolicy,
    value: providerPrivacyPolicy
  }), !!isEPrivacyUSA && h(BannerCookieProperty, {
    label: bannerI18n.ePrivacyUSA,
    value: ePrivacyUSA ? bannerI18n.yes : bannerI18n.no
  }), !noTechnicalDefinitions && technicalDefinitions.map(function (_ref2) {
    var type = _ref2.type,
        name = _ref2.name,
        host = _ref2.host,
        duration = _ref2.duration,
        durationUnit = _ref2.durationUnit,
        sessionDuration = _ref2.sessionDuration;
    return h(BannerCookieProperty, {
      key: name,
      label: bannerI18n.technicalCookieDefinition,
      value: h("span", {
        style: {
          fontFamily: "monospace"
        }
      }, applyDynamicsToHtml(name, codeDynamics))
    }, h(BannerCookieProperty, {
      label: bannerI18n.type,
      value: types[type].name
    }), !!host && h(BannerCookieProperty, {
      label: bannerI18n.host,
      value: h("span", {
        style: {
          fontFamily: "monospace"
        }
      }, host)
    }), ["local", "session", "indexedDb", "flash"].indexOf(type) === -1 && h(BannerCookieProperty, {
      label: bannerI18n.duration,
      value: sessionDuration ? "Session" : "".concat(duration, " ").concat(bannerI18n.durationUnit[durationUnit])
    }));
  }));
};

export { BannerCookie };