import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import { bannerInlineStylesScale } from "..";

function bannerHeaderInlineStyleContainer(_ref) {
  var _ref$layout = _ref.layout,
      type = _ref$layout.type,
      dialogBorderRadius = _ref$layout.dialogBorderRadius,
      _ref$design = _ref.design,
      borderWidth = _ref$design.borderWidth,
      borderColor = _ref$design.borderColor,
      restDesign = _objectWithoutProperties(_ref$design, ["borderWidth", "borderColor"]),
      _ref$headerDesign = _ref.headerDesign,
      inheritBg = _ref$headerDesign.inheritBg,
      bg = _ref$headerDesign.bg,
      padding = _ref$headerDesign.padding,
      antiAdBlocker = _ref.customCss.antiAdBlocker,
      mobile = _ref.mobile,
      pageRequestUuid4 = _ref.pageRequestUuid4;

  var useType = bannerInlineStylesScale(mobile, pageRequestUuid4, type, "banner");
  var useBorderWidth = bannerInlineStylesScale(mobile, pageRequestUuid4, borderWidth);
  var style = {
    padding: bannerInlineStylesScale(mobile, pageRequestUuid4, padding, undefined, true).map(function (p) {
      return "".concat(p, "px");
    }).join(" "),
    background: inheritBg ? restDesign.bg : bg,
    borderRadius: useType === "dialog" ? "".concat(dialogBorderRadius, "px ").concat(dialogBorderRadius, "px 0 0") : undefined,
    position: "sticky",
    zIndex: 9,
    top: 0
  };

  if (useType === "dialog" && useBorderWidth > 0) {
    style.borderTop = "".concat(useBorderWidth, "px solid ").concat(borderColor);
    style.borderLeft = style.borderTop;
    style.borderRight = style.borderTop;
  }

  return {
    className: antiAdBlocker === "y" ? undefined : "rcb-header-container",
    style: style
  };
}

export { bannerHeaderInlineStyleContainer };