import { useCallback, useMemo } from "react";
import { BANNER_SCALING_WHEN_WINDOW_WIDTH_LOWER } from "../components";
/**
 * Depending on the current screen, calculate the animation to use.
 *
 * Currently, we do not support animations on resize as we cannot calculate the
 * width of the overlay container as it does not exist on first render cycle of React.
 *
 * Drawback if animation only on mobile is enabled: if the window is first a mobile,
 * and the window gets resize it will reuse the animation on desktop again
 */

function useBannerAnimation() {
  var clientWidth = useMemo(function () {
    return window.innerWidth;
  }, []);
  var isMobile = clientWidth < BANNER_SCALING_WHEN_WINDOW_WIDTH_LOWER; // Check if it is mobile Firefox, as it does not support `position:sticky;bottom:0;` and animations.
  // Effect: The buttons and footer are not visible.

  var isMobileFirefox = useMemo(function () {
    var userAgent = window.navigator.userAgent.toLowerCase();
    return ["firefox", "gecko", "mobile", "android"].map(function (term) {
      return userAgent.indexOf(term) > -1;
    }).filter(Boolean).length === 4;
  }, []);
  return useCallback(function (_ref) {
    var animationIn = _ref.animationIn,
        animationInOnlyMobile = _ref.animationInOnlyMobile,
        animationOut = _ref.animationOut,
        animationOutOnlyMobile = _ref.animationOutOnlyMobile;
    var useAnimationIn = animationInOnlyMobile ? isMobile ? animationIn : "none" : animationIn;
    var useAnimationOut = animationOutOnlyMobile ? isMobile ? animationOut : "none" : animationOut;

    if (isMobileFirefox) {
      useAnimationIn = "none";
      useAnimationOut = "none";
    }

    return {
      useAnimationIn: useAnimationIn,
      useAnimationOut: useAnimationOut
    };
  }, []);
}

export { useBannerAnimation };