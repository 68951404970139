import _extends from "@babel/runtime/helpers/extends";
import _objectSpread from "@babel/runtime/helpers/objectSpread2";
import { bannerGroupsInlineStyleLinkMore, bannerGroupsInlineStyleCookieProperty } from ".";
import { useBanner } from "../../contexts";
import { isUrl } from "@devowl-wp/headless-content-unblocker";

var BannerCookieProperty = function BannerCookieProperty(_ref) {
  var label = _ref.label,
      value = _ref.value,
      children = _ref.children;
  var banner = useBanner();
  var isValueUrl = typeof value === "string" && isUrl(value);
  var useValue = isValueUrl ? h("a", {
    href: value,
    style: _objectSpread({
      wordBreak: "break-all"
    }, bannerGroupsInlineStyleLinkMore(banner, false).style),
    target: "_blank",
    rel: "noopener noreferrer"
  }, value) : typeof value === "string" ? h("span", {
    dangerouslySetInnerHTML: {
      __html: value
    }
  }) : value;
  return h("div", _extends({
    key: label
  }, bannerGroupsInlineStyleCookieProperty(banner)), label && h("strong", null, label, ":\xA0"), useValue, !!children && h("div", null, children));
};

export { BannerCookieProperty };