import { bannerInlineStylesScale } from "..";

function bannerHeaderInlineStyleHeadline(_ref) {
  var _ref$headerDesign = _ref.headerDesign,
      fontSize = _ref$headerDesign.fontSize,
      fontColor = _ref$headerDesign.fontColor,
      fontInheritFamily = _ref$headerDesign.fontInheritFamily,
      fontFamily = _ref$headerDesign.fontFamily,
      fontWeight = _ref$headerDesign.fontWeight,
      antiAdBlocker = _ref.customCss.antiAdBlocker,
      mobile = _ref.mobile,
      pageRequestUuid4 = _ref.pageRequestUuid4;
  return {
    className: antiAdBlocker === "y" ? undefined : "rcb-headline",
    style: {
      color: fontColor,
      fontSize: bannerInlineStylesScale(mobile, pageRequestUuid4, +fontSize),
      fontFamily: fontInheritFamily ? undefined : fontFamily,
      fontWeight: fontWeight
    }
  };
}

export { bannerHeaderInlineStyleHeadline };