import _extends from "@babel/runtime/helpers/extends";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useCallback } from "react";
import { useState, useMemo } from "react";
import { useBanner } from "../../contexts";
import { bannerButtonsInlineStyleAcceptAll, bannerButtonsInlineStyleAcceptEssentials, bannerButtonsInlineStyleAcceptIndividual, bannerButtonsInlineStyleEllipsesText, bannerButtonsInlineStyleSave } from ".";
import { isIE } from "../../utils";
import { AsciiSpinner } from "..";

var BannerButton = function BannerButton(_ref) {
  var inlineStyle = _ref.inlineStyle,
      type = _ref.type,
      onClick = _ref.onClick,
      children = _ref.children,
      framed = _ref.framed,
      busyOnClick = _ref.busyOnClick;

  if (type === "hide") {
    return null;
  }

  var _useState = useState(false),
      _useState2 = _slicedToArray(_useState, 2),
      isBusy = _useState2[0],
      setIsBusy = _useState2[1];

  var handleOnClick = useCallback(function () {
    if (!isBusy) {
      busyOnClick && setIsBusy(true);
      onClick === null || onClick === void 0 ? void 0 : onClick();
    }
  }, [onClick, isBusy, busyOnClick]);

  var _useState3 = useState(false),
      _useState4 = _slicedToArray(_useState3, 2),
      isHover = _useState4[0],
      setIsHover = _useState4[1];

  var banner = useBanner();
  var individualPrivacyOpen = banner.individualPrivacyOpen,
      _banner$decision = banner.decision,
      acceptAll = _banner$decision.acceptAll,
      acceptEssentials = _banner$decision.acceptEssentials,
      acceptAllOneRowLayout = banner.bodyDesign.acceptAllOneRowLayout;
  var interactionAttr = {
    onClick: handleOnClick,
    onMouseEnter: function onMouseEnter() {
      return setIsHover(true);
    },
    onMouseLeave: function onMouseLeave() {
      return setIsHover(false);
    }
  };
  var useStyleFn = useMemo(function () {
    switch (inlineStyle) {
      case "acceptEssentials":
        return bannerButtonsInlineStyleAcceptEssentials;

      case "acceptIndividual":
        return bannerButtonsInlineStyleAcceptIndividual;

      case "save":
        return bannerButtonsInlineStyleSave;

      default:
        return bannerButtonsInlineStyleAcceptAll;
    }
  }, [inlineStyle]); // Also create a spacing `height:10` container because `marginBottom` causes issues with `position:sticky`

  return h("div", _extends({}, type === "button" ? interactionAttr : {}, useStyleFn(banner, isHover, framed, // IE does not support flex with calc()
  isIE() || acceptAll === "hide" || acceptEssentials === "hide" || individualPrivacyOpen ? false : acceptAllOneRowLayout)), h("span", _extends({}, bannerButtonsInlineStyleEllipsesText(), type === "link" ? interactionAttr : {}), isBusy ? h(AsciiSpinner, null) : children));
};

export { BannerButton };